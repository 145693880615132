import { createReducer, on } from '@ngrx/store';
import {
  applicationSelectionComplete,
  applicationSelectionFailure,
  inputFilterAddPrimaryAggregator,
  inputFilterResetPrimaryAggregator,
  inputFilterRmPrimaryAggregator,
  inputFilterSetDateRange,
  markAppElementAsDone,
  markAppElementAsNotDone,
  optimizeResponseError,
  optimizeResponseShowError,
  optimizeResponseSuccess,
  optimizeResponseWarning,
  optimizeRunSuccess,
  optimSpaceSelectionFailure,
  optimSpaceUploadFailure,
  optimSpaceSelectionComplete,
  optimSpaceUploadComplete,
  outputFilterAddPrimaryAggregator,
  outputFilterResetPrimaryAggregator,
  outputFilterRmPrimaryAggregator,
  outputFilterSetDateRange,
  outputSelectionComplete,
  outputSelectionFailure,
  outputVisuUserUpdate,
  parameterSelectionComplete,
  parameterSelectionFailure,
  parameterUpdateFailure,
  favoriteParameterSelectionCompleted,
  favoriteParameterSelectionFailure,
  rehydrationComplete,
  rehydrationFailure,
  resetApplicationSuccess,
  showInputVisu,
  showOutputVisu,
  toggleInputKpis,
  toggleList,
  togglePackage,
  toggleOutputKpis,
  toggleTimeline,
  toggleMap,
  optimizeRunFailure,
  closeStatusBar,
  toggleCaption,
  applicationVisualization,
  mapChangeTransportMode,
  toggleWarehouse,
  setMapProvider,
  parameterUploadComplete,
  showInList,
  clearList
} from './application.actions';
import { ApplicationVersion } from '../../models/Application';
import { OptimSpaceModel } from 'src/app/core/models/OptimSpaceModel';
import { InputModel } from 'src/app/core/models/InputModel';
import { OutputModel } from 'src/app/core/models/OutputModel';
import {
  NewVisuData,
  StructuralAggregator,
  VisuContainer,
  VisuDataStateUtils
} from 'src/app/core/models/Visu';
import { Kpi } from 'src/app/core/models/Kpi';
import { Output } from '@angular/core';
import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { MessageCategory } from 'src/app/core/models/MessageCategory';

// Comment about the optimization
// At the moment, we do not allow the user to run several optimisation in parallel
// on a same application due to technical limitation in the back end that we should
// tackle very soon (see https://gitlab.com/atoptima/web/galia-back-end/-/issues/54).

export type VisuScreenState = 'nothing' | 'input' | 'output';
export type MapProvider = 'mapbox' | 'here';
const initialVisuScreenState: VisuScreenState = 'nothing';

// State of widgets (list, timeline, kpis...)
export interface ApplicationControlState {
  visuOnScreen: VisuScreenState;
  showInputKpis: boolean;
  showOutputKpis: boolean;
  showTimeline: boolean;
  showList: boolean;
  showPackage: boolean;
  showMap: boolean;
  showCaption: boolean;
  showWarehouse: boolean;
}

const initialApplicationControlState = {
  visuOnScreen: initialVisuScreenState,
  showInputKpis: false,
  showOutputKpis: false,
  showTimeline: false,
  showList: false,
  showPackage: false,
  showMap: false,
  showCaption: false,
  showWarehouse: false
};

export type AlertDisplayLocation = 'mainbar' | 'datapicker' | 'everywhere';
export type AsyncJobCategory = 'optimize' | 'recompute';

export interface ApplicationAlertMessage {
  category: MessageCategory;
  message: string;
  code: AlertDisplayLocation;
  details?: { action: string; title: string; htmlMessage: string };
}

export interface AsyncJobStatus {
  jobId: number;
  appPath: string;
  category: AsyncJobCategory;
  optimSpaceId?: number;
  inputId?: number;
  outputId?: number;
}

export interface VisuFilter {
  visuStartDate?: Date; // start date of the visualization
  visuEndDate?: Date; // end date of the visualization
  startDate?: Date;
  endDate?: Date;
  primaryAggregators: number[]; // if empty, we display all primary aggregators
  timelineStructAggregators?: number[]; // selected struct agg from the timeline
}

function visuFilter(visu: NewVisuData): VisuFilter {
  if (visu == null) {
    return undefined;
  }
  const primaryAggregators = new Array<number>();
  const timelineStructAggregators = new Array<number>();
  let startDate = undefined;
  let endDate = undefined;
  if (VisuDataStateUtils.hasTimeline(visu)) {
    startDate = new Date('2999-12-31');
    endDate = new Date(1);
    Object.keys(visu.timeline_datas.by_id).forEach((key) => {
      const elemStartDateStr = visu.timeline_datas.by_id[key].start_date ?? '2999-12-31';
      const elemEndDateStr = visu.timeline_datas.by_id[key].finish_date ?? '1970-1-1';
      const elemStartDate = new Date(elemStartDateStr);
      const elemEndDate = new Date(elemEndDateStr);
      if (elemStartDate < startDate) {
        startDate = elemStartDate;
      }
      if (elemEndDate > endDate) {
        endDate = elemEndDate;
      }
    });
  }
  return {
    visuStartDate: startDate,
    visuEndDate: endDate,
    startDate,
    endDate,
    primaryAggregators,
    timelineStructAggregators
  };
}

function updateDateRange(filter: VisuFilter, newStartDate: Date, newEndDate: Date) {
  if (filter.visuStartDate == null || filter.visuEndDate == null) {
    return filter;
  }
  let startDate = newStartDate;
  let endDate = newEndDate;

  if (newStartDate < filter.visuStartDate) {
    startDate = filter.visuStartDate;
  }

  if (newEndDate > filter.visuEndDate) {
    endDate = filter.visuEndDate;
  }
  return { ...filter, startDate, endDate };
}

export function _addToRunningOptimJobs(
  runOptimJobs,
  app: string,
  inputId: number,
  key: 'optimize' | 'recompute',
  job
) {
  // if there is no entry to store the running jobs of an app, we create the entry.
  if (!(app in runOptimJobs)) {
    runOptimJobs[app] = {};
  }
  const runOptimJobsOfApp = { ...runOptimJobs[app] };
  if (!(inputId in runOptimJobsOfApp)) {
    runOptimJobsOfApp[inputId] = {};
  }
  const runOptimJobsOfInput = { ...runOptimJobsOfApp[inputId] };
  runOptimJobsOfInput[key] = job;
  runOptimJobsOfApp[inputId] = runOptimJobsOfInput;
  runOptimJobs[app] = runOptimJobsOfApp;
  return;
}

export function _rmFromRunningOptimJobs(
  runOptimJobs,
  app: string,
  inputId: number,
  key: 'optimize' | 'recompute'
) {
  if (!(app in runOptimJobs)) {
    console.warn(
      `Trying to delete optim job of application ${app} but not entry for this application.`
    );
    return;
  }
  if (!(inputId in runOptimJobs[app])) {
    console.warn(
      `Trying to delete optim job of application ${app} and input ${inputId} but no entry for this input.`
    );
    return;
  }

  const runOptimJobsOfApp = { ...runOptimJobs[app] };
  const runOptimJobsOfInput = { ...runOptimJobs[app][inputId] };
  delete runOptimJobsOfInput[key];
  runOptimJobsOfApp[inputId] = runOptimJobsOfInput;

  const { optimize, recompute } = runOptimJobsOfApp[inputId];
  if (!optimize && !recompute) {
    delete runOptimJobsOfApp[inputId];
  }
  runOptimJobs[app] = runOptimJobsOfApp;

  if (Object.keys(runOptimJobs[app]).length == 0) {
    delete runOptimJobs[app];
  }
  return;
}

export interface ApplicationState {
  application: ApplicationVersion;
  optimSpace: OptimSpaceModel;
  input: InputModel;
  output: OutputModel;
  controls: ApplicationControlState;
  inputVisu?: NewVisuData;
  inputVisuFilter?: VisuFilter;
  outputVisu?: NewVisuData;
  outputVisuFilter?: VisuFilter;
  inputKpis?: Kpi[];
  outputKpis?: Kpi[];
  eventListeners: string[];
  alertMessage?: ApplicationAlertMessage;
  mapProvider?: MapProvider;

  /**
   * The key is the appPath (at the moment only one job per application is running).
   * It is always rehydrated from the local storage.
   */
  runningOptimJobs: {
    [app: string]: {
      [input: number]: { optimize?: AsyncJobStatus; recompute?: AsyncJobStatus };
    };
  };

  /**
   * TODO: remove the visu for recompute from the global state (issue 154)
   */
  outputVisuForRecompute?: NewVisuData;
  selectedAggregatorIdFromMap?: number;
}

export const initialApplicationState: ApplicationState = {
  application: new ApplicationVersion(),
  optimSpace: new OptimSpaceModel(),
  input: new InputModel(),
  output: new OutputModel(),
  controls: initialApplicationControlState,
  eventListeners: [],
  runningOptimJobs: {}
};

export const applicationReducer = createReducer<ApplicationState>(
  initialApplicationState,
  on(
    rehydrationComplete,
    (
      state,
      {
        application,
        optimSpace,
        input,
        inputVisu,
        inputKpis,
        output,
        outputVisu,
        outputKpis
      }
    ) => {
      const eventListeners = [];
      if (application.application.path != null) {
        eventListeners.push(application.application.path);
      }

      let visuOnScreen: VisuScreenState = 'nothing';
      const controlState = { ...initialApplicationControlState };

      if (inputVisu != null) {
        visuOnScreen = outputVisu != null ? 'output' : 'input';
      }
      if (visuOnScreen !== 'nothing') {
        const visu = visuOnScreen === 'input' ? inputVisu : outputVisu;
        VisuDataStateUtils.updateApplicationState(visu, controlState);
      }
      const inputVisuFilter = visuFilter(inputVisu);
      const outputVisuFilter = visuFilter(outputVisu);

      return {
        ...state,
        application,
        optimSpace,
        input,
        inputVisu,
        inputVisuFilter,
        inputKpis,
        output,
        outputVisu,
        outputVisuFilter,
        outputKpis,
        outputVisuForRecompute: undefined,
        controls: {
          ...controlState,
          visuOnScreen
        },
        eventListeners,
        alertMessage: null
      };
    }
  ),

  on(applicationSelectionComplete, (state, { app }) => {
    return {
      ...state,
      application: app,
      optimSpace: new OptimSpaceModel(),
      input: new InputModel(),
      output: new OutputModel(),
      inputVisu: undefined,
      inputFilter: undefined,
      inputKpis: undefined,
      outputVisu: undefined,
      outputFilter: undefined,
      outputKpis: undefined,
      outputVisuForRecompute: undefined,
      controls: { ...initialApplicationControlState },
      eventListeners: Array.from(new Set([...state.eventListeners, app.application.path])),
      alertMessage: null
    };
  }),

  on(applicationVisualization, (state, { name, path }) => {
    return {
      ...state,
      application: {
        ...state.application,
        application: {
          ...state.application.application,
          name,
          path
        }
      }
    };
  }),

  on(
    optimSpaceSelectionFailure,
    outputSelectionFailure,
    parameterSelectionFailure,
    favoriteParameterSelectionFailure,
    (state, { message, details }) => {
      return {
        ...state,
        alertMessage: {
          code: 'datapicker',
          category: 'error',
          message,
          details
        }
      };
    }
  ),

  on(
    applicationSelectionFailure,
    rehydrationFailure,
    optimizeRunFailure,
    optimSpaceUploadFailure,
    parameterUpdateFailure,
    (state, { message }) => {
      return {
        ...state,
        alertMessage: {
          code: 'mainbar',
          category: 'error',
          message
        }
      };
    }
  ),

  on(optimSpaceSelectionComplete, (state, { optimSpace, input, inputKpis, inputVisu }) => {
    const inputVisuFilter = visuFilter(inputVisu);
    const controlState = { ...initialApplicationControlState };

    VisuDataStateUtils.updateApplicationState(inputVisu, controlState);
    return {
      ...state,
      optimSpace: {
        ...optimSpace,
        parameter: OptimSpaceModel.getParameters(optimSpace, state?.optimSpace)
      },
      input,
      inputVisu,
      inputVisuFilter,
      inputKpis,
      output: new OutputModel(),
      outputVisu: undefined,
      outputVisuFilter: undefined,
      outputKpis: undefined,
      outputVisuForRecompute: undefined,
      controls: { ...controlState, visuOnScreen: 'input' },
      alertMessage: null
    };
  }),

  on(outputSelectionComplete, (state, { output, outputVisu, outputKpis }) => {
    const outputVisuFilter = visuFilter(outputVisu);
    const controlState = { ...initialApplicationControlState };
    VisuDataStateUtils.updateApplicationState(outputVisu, controlState);
    return {
      ...state,
      output,
      outputVisu,
      outputVisuFilter,
      outputKpis,
      outputVisuForRecompute: undefined,
      controls: { ...controlState, visuOnScreen: 'output' },
      alertMessage: null
    };
  }),

  on(toggleInputKpis, (state, _) => {
    const hasInputKpis = state.inputKpis != null;
    const toggle = !state.controls.showInputKpis;
    const showInputKpis = hasInputKpis && toggle;
    return { ...state, controls: { ...state.controls, showInputKpis } };
  }),

  on(toggleOutputKpis, (state, _) => {
    const hasOutputKpis = state.outputKpis != null;
    const toggle = !state.controls.showOutputKpis;
    const showOutputKpis = hasOutputKpis && toggle;
    return { ...state, controls: { ...state.controls, showOutputKpis } };
  }),

  on(toggleCaption, (state, _) => {
    const showInputCaption =
      (state.controls.visuOnScreen === 'input' &&
        VisuDataStateUtils.hasCaption(state.inputVisu)) ||
      VisuDataStateUtils.hasWarehouse(state.inputVisu);
    const showOutputCaption =
      (state.controls.visuOnScreen === 'output' &&
        VisuDataStateUtils.hasCaption(state.outputVisu)) ||
      VisuDataStateUtils.hasWarehouse(state.outputVisu);
    const toggle = !state.controls.showCaption;
    const showCaption = toggle && (showInputCaption || showOutputCaption);
    return { ...state, controls: { ...state.controls, showCaption } };
  }),

  on(toggleTimeline, (state, _) => {
    const showInputTimeline =
      state.controls.visuOnScreen === 'input' &&
      VisuDataStateUtils.hasTimeline(state.inputVisu);
    const showOutputTimeline =
      state.controls.visuOnScreen === 'output' &&
      VisuDataStateUtils.hasTimeline(state.outputVisu);
    const toggle = !state.controls.showTimeline;
    const showTimeline = toggle && (showInputTimeline || showOutputTimeline);
    return { ...state, controls: { ...state.controls, showTimeline, showList: false } };
  }),

  on(toggleList, (state, _) => {
    const showInputList =
      state.controls.visuOnScreen === 'input' &&
      VisuDataStateUtils.hasList(state.inputVisu);
    const showOutputList =
      state.controls.visuOnScreen === 'output' &&
      VisuDataStateUtils.hasList(state.outputVisu);
    const toggle = !state.controls.showList;
    const showList = toggle && (showInputList || showOutputList);
    return { ...state, controls: { ...state.controls, showList, showTimeline: false } };
  }),

  on(showInList, (state, { aggregatorId }) => {
    const showInputList =
      state.controls.visuOnScreen === 'input' &&
      VisuDataStateUtils.hasList(state.inputVisu);
    const showOutputList =
      state.controls.visuOnScreen === 'output' &&
      VisuDataStateUtils.hasList(state.outputVisu);
    const showList = showInputList || showOutputList;
    return {
      ...state,
      controls: { ...state.controls, showList, showTimeline: false },
      selectedAggregatorIdFromMap: aggregatorId
    };
  }),

  on(clearList, (state) => {
    return {
      ...state,
      selectedAggregatorIdFromMap: undefined
    };
  }),

  on(togglePackage, (state, _) => {
    const showInputPackage =
      state.controls.visuOnScreen === 'input' &&
      VisuDataStateUtils.hasPackage(state.inputVisu);
    const showOutputPackage =
      state.controls.visuOnScreen === 'output' &&
      VisuDataStateUtils.hasPackage(state.outputVisu);
    const toggle = !state.controls.showPackage;
    const showPackage = toggle && (showInputPackage || showOutputPackage);
    return { ...state, controls: { ...state.controls, showPackage, showMap: false } };
  }),

  on(toggleWarehouse, (state, _) => {
    const showInputWarehouse =
      state.controls.visuOnScreen === 'input' &&
      VisuDataStateUtils.hasWarehouse(state.inputVisu);
    const showOutputWarehouse =
      state.controls.visuOnScreen === 'output' &&
      VisuDataStateUtils.hasWarehouse(state.outputVisu);
    const toggle = !state.controls.showWarehouse;
    const showWarehouse = toggle && (showInputWarehouse || showOutputWarehouse);
    return { ...state, controls: { ...state.controls, showWarehouse, showMap: false } };
  }),

  on(toggleMap, (state, _) => {
    const showInputMap =
      state.controls.visuOnScreen === 'input' && VisuDataStateUtils.hasMap(state.inputVisu);
    const showOutputMap =
      state.controls.visuOnScreen === 'output' &&
      VisuDataStateUtils.hasMap(state.outputVisu);
    const toggle = !state.controls.showMap;
    const showMap = toggle && (showInputMap || showOutputMap);
    return { ...state, controls: { ...state.controls, showMap, showPackage: false } };
  }),

  on(resetApplicationSuccess, (state, _) => {
    return {
      ...initialApplicationState,
      eventListeners: state.eventListeners,
      runningOptimJobs: state.runningOptimJobs
    };
  }),

  on(optimizeRunSuccess, (state, { appPath, jobId, inputId, optimSpaceId, category }) => {
    const runningOptimJobs = { ...state.runningOptimJobs };
    const job = { jobId, appPath, category, optimSpaceId, inputId };
    _addToRunningOptimJobs(runningOptimJobs, appPath, inputId, category, job);
    return { ...state, runningOptimJobs };
  }),

  on(optimizeResponseShowError, (state, { appPath, inputId, category, message }) => {
    const alertMessage: ApplicationAlertMessage = {
      category: 'error',
      message,
      code: 'mainbar'
    };
    const runningOptimJobs = { ...state.runningOptimJobs };
    _rmFromRunningOptimJobs(runningOptimJobs, appPath, inputId, category);
    return {
      ...state,
      runningOptimJobs,
      alertMessage
    };
  }),

  on(optimizeResponseSuccess, (state, { output, outputVisu, outputKpis, category }) => {
    const jobCategory = category === 'optimize' ? 'Optimization' : 'Recomputation';
    const alertMessage: ApplicationAlertMessage = {
      category: 'success',
      message: `${jobCategory} has successfully finished. You are watching the solution.`,
      code: 'mainbar'
    };
    const runningOptimJobs = { ...state.runningOptimJobs };
    const { appPath, inputId } = output;
    _rmFromRunningOptimJobs(runningOptimJobs, appPath, inputId, category);
    const outputVisuFilter = visuFilter(outputVisu);
    const controlState = { ...initialApplicationControlState };
    VisuDataStateUtils.updateApplicationState(outputVisu, controlState);
    return {
      ...state,
      output,
      outputVisu,
      outputKpis,
      outputVisuFilter,
      outputVisuForRecompute: undefined,
      runningOptimJobs,
      controls: { ...controlState, visuOnScreen: 'output' },
      alertMessage
    };
  }),

  on(
    optimizeResponseWarning,
    (state, { warnings, output, outputVisu, outputKpis, category }) => {
      const jobCategory = category === 'optimize' ? 'Optimization' : 'Recomputation';
      const alertMessage: ApplicationAlertMessage = {
        category: 'warning',
        message: `${jobCategory} has finished with warnings. You are watching the solution.`,
        code: 'mainbar'
      };
      const runningOptimJobs = { ...state.runningOptimJobs };
      const { appPath, inputId } = output;
      _rmFromRunningOptimJobs(runningOptimJobs, appPath, inputId, category);
      const outputVisuFilter = visuFilter(outputVisu);
      const controlState = { ...initialApplicationControlState };
      VisuDataStateUtils.updateApplicationState(outputVisu, controlState);
      return {
        ...state,
        output,
        outputVisu,
        outputKpis,
        outputVisuFilter,
        outputVisuForRecompute: undefined,
        runningOptimJobs,
        controls: { ...controlState, visuOnScreen: 'output' },
        alertMessage
      };
    }
  ),

  on(optimizeResponseError, (state, { output, errors, category }) => {
    let message = 'An error occured while optimizing the input.';
    if (category === 'recompute') {
      message = 'An error occured while recomputing the output.';
    }

    const alertMessage: ApplicationAlertMessage = {
      category: 'error',
      message,
      code: 'mainbar'
    };
    const runningOptimJobs = { ...state.runningOptimJobs };
    const { appPath, inputId } = output;
    _rmFromRunningOptimJobs(runningOptimJobs, appPath, inputId, category);
    return {
      ...state,
      output,
      outputVisu: new NewVisuData(),
      outputKpis: [],
      outputVisuFilter: null,
      outputVisuForRecompute: undefined,
      runningOptimJobs,
      alertMessage
    };
  }),
  //TODO: CHANGE TO NEW VISU DATA
  on(
    outputVisuUserUpdate,
    (state, { sourcePrimAggPos, sourceElemValue, targetPrimAggPos, targetElemValue }) => {
      const curOutputVisu = state.outputVisu;
      const structAggById = { ...curOutputVisu.structural_aggregators.by_id };
      const appElementsById = { ...curOutputVisu.app_elements.by_id };

      const idSourceStructAgg = sourcePrimAggPos + 1;
      const idTargetStructAgg = targetPrimAggPos + 1;
      let targetAppElemIds = [];
      if (sourcePrimAggPos == targetPrimAggPos) {
        // Move the element id in the array.
        const appElemIds = [...structAggById[idSourceStructAgg].app_elem_ids];
        targetAppElemIds = appElemIds;
        const sourceElemPos = appElemIds.indexOf(sourceElemValue);
        const targetElemPos = appElemIds.indexOf(targetElemValue);
        moveItemInArray(appElemIds, sourceElemPos, targetElemPos);

        appElementsById[appElemIds[targetElemPos]] = {
          ...appElementsById[appElemIds[targetElemPos]],
          _dragAndDropped: true
        };

        // Replace the old primary aggregator by the new one.
        structAggById[idSourceStructAgg] = {
          ...structAggById[idSourceStructAgg],
          app_elem_ids: appElemIds
        } as StructuralAggregator;
      } else {
        // Transfer element id between arrays.
        const sourceAppElemIds = [...structAggById[idSourceStructAgg].app_elem_ids];
        targetAppElemIds = [...structAggById[idTargetStructAgg].app_elem_ids];
        const sourceElemPos = sourceAppElemIds.indexOf(sourceElemValue);
        const targetElemPos = targetAppElemIds.indexOf(targetElemValue);
        transferArrayItem(sourceAppElemIds, targetAppElemIds, sourceElemPos, targetElemPos);

        appElementsById[targetAppElemIds[targetElemPos]] = {
          ...appElementsById[targetAppElemIds[targetElemPos]],
          _dragAndDropped: true
        };
        // Replace old aggregators by new ones.
        structAggById[idSourceStructAgg] = {
          ...structAggById[idSourceStructAgg],
          app_elem_ids: sourceAppElemIds
        } as StructuralAggregator;
        structAggById[idTargetStructAgg] = {
          ...structAggById[idTargetStructAgg],
          app_elem_ids: targetAppElemIds
        } as StructuralAggregator;
      }
      const structural_aggregators = {
        all_ids: curOutputVisu.structural_aggregators.all_ids,
        by_id: structAggById
      } as VisuContainer<StructuralAggregator>;

      const app_elements = {
        all_ids: curOutputVisu.app_elements.all_ids,
        by_id: appElementsById
      };

      const outputVisu = {
        ...curOutputVisu,
        structural_aggregators,
        app_elements
      };

      // TODO: I decided to separate the outputVisu and the outputVisu for recompute but I
      // think it's irrelevant now. We can keep only one output visu and a flag to activate
      // the "Recompute" button when the user changes the visu .
      return { ...state, outputVisu, outputVisuForRecompute: { ...outputVisu } };
    }
  ),

  on(markAppElementAsDone, (state, { appElementId }) => {
    const app_element = {
      ...state.outputVisu.app_elements.by_id[appElementId],
      _taskDone: true
    };
    const app_elements = { ...state.outputVisu.app_elements, app_element };
    const outputVisu = { ...state.outputVisu, app_elements };
    return { ...state, outputVisu, outputVisuForRecompute: { ...outputVisu } };
  }),

  on(markAppElementAsNotDone, (state, { appElementId }) => {
    const app_element = {
      ...state.outputVisu.app_elements.by_id[appElementId],
      _taskDone: false
    };
    const app_elements = { ...state.outputVisu.app_elements, app_element };
    const outputVisu = { ...state.outputVisu, app_elements };
    return { ...state, outputVisu, outputVisuForRecompute: { ...outputVisu } };
  }),

  on(inputFilterSetDateRange, (state, { startDate, endDate }) => {
    if (state.inputVisuFilter == null) {
      return state;
    }
    return {
      ...state,
      inputVisuFilter: updateDateRange(state.inputVisuFilter, startDate, endDate)
    };
  }),

  on(outputFilterSetDateRange, (state, { startDate, endDate }) => {
    if (state.outputVisuFilter == null) {
      return state;
    }
    return {
      ...state,
      outputVisuFilter: updateDateRange(state.outputVisuFilter, startDate, endDate)
    };
  }),

  on(inputFilterAddPrimaryAggregator, (state, { id }) => {
    const curPrimaryAggregators = state.inputVisuFilter?.primaryAggregators;
    if (curPrimaryAggregators == null) {
      return state;
    }
    if (id === -1) {
      //select all items
      return {
        ...state,
        inputVisuFilter: {
          ...state.inputVisuFilter,
          primaryAggregators: state.inputVisu.structural_aggregators.all_ids.map(
            (id) => +id
          )
        }
      };
    }
    const ids = new Set<number>(curPrimaryAggregators);
    ids.add(id);
    const primaryAggregators = Array.from<number>(ids);
    return {
      ...state,
      inputVisuFilter: {
        ...state.inputVisuFilter,
        primaryAggregators
      }
    };
  }),

  on(outputFilterAddPrimaryAggregator, (state, { id }) => {
    const curPrimaryAggregators = state.outputVisuFilter?.primaryAggregators;
    if (curPrimaryAggregators == null) {
      return state;
    }
    if (id === -1) {
      //select all items
      console.log(
        'select all items',
        state.outputVisu.structural_aggregators.all_ids.map((id) => +id)
      );

      return {
        ...state,
        outputVisuFilter: {
          ...state.outputVisuFilter,
          primaryAggregators: state.outputVisu.structural_aggregators.all_ids.map(
            (id) => +id
          )
        }
      };
    }
    const ids = new Set<number>(curPrimaryAggregators);
    ids.add(id);
    const primaryAggregators = Array.from<number>(ids);
    return {
      ...state,
      outputVisuFilter: {
        ...state.outputVisuFilter,
        primaryAggregators
      }
    };
  }),

  on(inputFilterRmPrimaryAggregator, (state, { id }) => {
    const curPrimaryAggregators = state.inputVisuFilter?.primaryAggregators;
    if (curPrimaryAggregators == null) {
      return state;
    }
    const ids = new Set<number>(curPrimaryAggregators);
    ids.delete(id);
    const primaryAggregators = Array.from<number>(ids);
    return {
      ...state,
      inputVisuFilter: {
        ...state.inputVisuFilter,
        primaryAggregators
      }
    };
  }),

  on(outputFilterRmPrimaryAggregator, (state, { id }) => {
    const curPrimaryAggregators = state.outputVisuFilter?.primaryAggregators;
    if (curPrimaryAggregators == null) {
      return state;
    }
    const ids = new Set<number>(curPrimaryAggregators);
    ids.delete(id);
    const primaryAggregators = Array.from<number>(ids);
    return {
      ...state,
      outputVisuFilter: {
        ...state.outputVisuFilter,
        primaryAggregators
      }
    };
  }),

  on(inputFilterResetPrimaryAggregator, (state) => {
    return {
      ...state,
      inputVisuFilter: {
        ...state.inputVisuFilter,
        primaryAggregators: []
      }
    };
  }),

  on(outputFilterResetPrimaryAggregator, (state) => {
    return {
      ...state,
      outputVisuFilter: {
        ...state.outputVisuFilter,
        primaryAggregators: []
      }
    };
  }),

  on(optimSpaceUploadComplete, (state, { optimSpace, input, inputVisu, inputKpis }) => {
    const inputVisuFilter = visuFilter(inputVisu);

    let alertMessage: ApplicationAlertMessage = {
      category: 'success',
      message: 'Successful upload of the new input.',
      code: 'mainbar'
    };
    if (input.warnings != null && input.warnings.length > 0) {
      alertMessage = {
        category: 'warning',
        message:
          'Successful upload of the new input. The application returned some warnings.',
        code: 'mainbar'
      };
    }
    const controlState = { ...initialApplicationControlState };
    VisuDataStateUtils.updateApplicationState(inputVisu, controlState);

    return {
      ...state,
      optimSpace: {
        ...optimSpace,
        parameter: OptimSpaceModel.getParameters(optimSpace, state?.optimSpace)
      },
      input,
      inputVisu,
      inputKpis,
      inputVisuFilter,
      output: new Output(),
      outputVisu: null,
      outputVisuFilter: null,
      outputVisuForRecompute: null,
      controls: { ...controlState, visuOnScreen: 'input' },
      alertMessage
    };
  }),

  on(showInputVisu, (state) => {
    const visuOnScreen: VisuScreenState = 'input';
    const controls = { ...state.controls, visuOnScreen };
    return { ...state, controls };
  }),

  on(showOutputVisu, (state) => {
    const visuOnScreen: VisuScreenState = 'output';
    const controls = { ...state.controls, visuOnScreen };
    return { ...state, controls };
  }),

  on(closeStatusBar, (state) => {
    return { ...state, alertMessage: null };
  }),

  on(mapChangeTransportMode, (state, { transportMode, visuOnScreen }) => {
    return {
      ...state,
      outputVisu: {
        ...state.outputVisu,
        options:
          visuOnScreen === 'output'
            ? { ...state.outputVisu?.options, transportMode }
            : { ...state.outputVisu?.options, transportMode: undefined }
      },
      inputVisu: {
        ...state.inputVisu,
        options:
          visuOnScreen === 'input'
            ? { ...state.outputVisu?.options, transportMode }
            : { ...state.inputVisu?.options, transportMode: undefined }
      }
    };
  }),

  on(setMapProvider, (state, { provider }) => {
    return {
      ...state,
      mapProvider: provider
    };
  }),

  on(parameterSelectionComplete, (state, { parameterId }) => {
    return {
      ...state,
      optimSpace: {
        ...state.optimSpace,
        parameter: {
          ...state.optimSpace.parameter,
          parameterId
        }
      }
    };
  }),

  on(favoriteParameterSelectionCompleted, (state, { favoriteParameterId }) => {
    return {
      ...state,
      optimSpace: {
        ...state.optimSpace,
        parameter: {
          ...state.optimSpace.parameter,
          favoriteParameterId
        }
      }
    };
  }),

  on(parameterUploadComplete, (state, { parameterId }) => {
    return {
      ...state,
      optimSpace: {
        ...state.optimSpace,
        parameter: {
          ...state.optimSpace.parameter,
          parameterId
        }
      }
    };
  })
);
