import { OutputModel, OutputModelV4 } from './OutputModel';

/**
 * The Dto received as response of asynchronous requests
 * (received through the web-socket interface)
 */
export class WebSocketResponseDto {
  public appPath: string;
  public appVersion: string; //This field is not given in the web socket response. We populate it in the frontend. In the V4 of the API this info will come from GBE
  public inputId: number;
  public jobId: number;
  public output: OutputModel;

  /**
   * User's metadata
   */
  public metadata: string;
}

export class WebSocketResponseV4Dto {
  jobId: number;
  appPath: string;
  appVersion: string;
  inputId: number;
  optimSpaceId: number;
  metadata: string;
  parameterId: number;
  currentStatus: string;
  outputs: OutputModelV4[];
  createdAt: Date;
  jobErrors: any[];
}

export class WebSocketResponseMapper {
  static mapFromV4ToV3(v4: WebSocketResponseV4Dto): WebSocketResponseDto {
    const output = v4.outputs[0];
    return {
      jobId: v4.jobId,
      appPath: v4.appPath,
      appVersion: v4.appVersion,
      inputId: v4.inputId,
      metadata: v4.metadata,
      output: {
        id: output?.id,
        name: undefined,
        appPath: v4.appPath,
        optimSpaceId: v4.optimSpaceId,
        inputId: v4.inputId,
        createdAt: v4.createdAt,
        data: output?.data,
        parameterId: v4.parameterId,
        errors: v4.jobErrors,
        warnings: output?.outputWarnings ?? []
      }
    };
  }
}
