import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { EndPointV4 } from '../enum/endpoint.enum';
import { BASE_API_URL } from '../utils/injectors';
import { InputModel } from '../models/InputModel';
import { VisuAndKpis } from '../models/VisuAndKpis';

type GetInputResponseV4 = {
  id: number;
  created_at: Date;
  data: any;
  warnings: any[];
  application: {
    version: string;
    app_path: string;
  };
  optim_space: {
    id: number;
    name: string;
    description: string;
  };
  data_space: {
    id: number;
    name: string;
  };
};

@Injectable({
  providedIn: 'root'
})
export class InputService {
  private readonly urlInputsV4: string;

  constructor(
    private readonly http: HttpClient,
    @Inject(BASE_API_URL) readonly apiUrl: string
  ) {
    this.urlInputsV4 = `${apiUrl}/${EndPointV4.INPUTS}`;
  }

  /**
   * HTTP request to Galia API to get an optimSpace input.
   * We add an id to input visu data to know when visu data changes.
   * @param inputId a number that represents the id of the input
   * @returns an observable that emits the selected input
   */
  getInput(inputId: number): Observable<InputModel> {
    return this.http.request('GET', `${this.urlInputsV4}/${inputId}`).pipe(
      map((r: GetInputResponseV4): InputModel => {
        return {
          id: r.id,
          appPath: r.application.app_path,
          createdAt: r.created_at,
          data: r.data,
          optimSpaceId: r.optim_space.id,
          warnings: r.warnings
        };
      })
    );
  }

  getInputVisuAndKpis(inputId: number): Observable<VisuAndKpis> {
    return this.http.get<VisuAndKpis>(`${this.urlInputsV4}/${inputId}/visu-and-kpis`).pipe(
      map((data: any) => {
        const visuAndKpis = new VisuAndKpis(data);
        visuAndKpis.visuData._id = `input-${inputId}`;
        return visuAndKpis;
      })
    );
  }

  /**
   * HTTP request to delete an input
   * @param optimSpaceId a number that represents the input ID
   * @returns
   */
  deleteInput(inputId: number): Observable<any> {
    return this.http.delete(`${this.urlInputsV4}/${inputId}`);
  }
}
