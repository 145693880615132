<footer class="text-xs text-center py-2">
  <span
    >Copyright &copy; 2025
    <a class="link" href="https://www.atoptima.com/" target="_blank">Atoptima</a>
  </span>
  <!-- <span class="px-2">|</span>
  <span><a class="link" href="#" target="_blank">Terms</a></span>
  <span class="px-2">|</span>
  <span><a class="link" href="#" target="_blank">Privacy policy</a></span> -->
  <span class="px-2">|</span>
  <span>v{{ appVersion }}</span>
</footer>
