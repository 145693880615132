import { UrlTree } from '@angular/router';
import { createAction, props } from '@ngrx/store';
import { ApplicationVersion } from 'src/app/core/models/Application';
import { InputModel } from 'src/app/core/models/InputModel';
import { OptimSpaceModel } from 'src/app/core/models/OptimSpaceModel';
import { Kpi } from 'src/app/core/models/Kpi';
import { OutputModel } from 'src/app/core/models/OutputModel';
import { ParameterItemModel } from 'src/app/core/models/ParametersModel';
import { User } from 'src/app/core/models/User';
import { NewVisuData } from 'src/app/core/models/Visu';
import { WebSocketResponseDto } from 'src/app/core/models/WebSocketResponse.dto';
import { AsyncJobCategory, MapProvider, VisuScreenState } from './application.reducers';

/**
 * Things I learnt from ngrx & angular.
 *
 * Name of actions should always be [Source] Event. Actions are unique, don't use
 * them as commands. "Source" should be interpreted as who triggers the action.
 *
 * We need to pass the ActivatedRoute from the routed component because
 * ActivatedRoute is specific to each routed component. It explains why we have
 * a UrlTree in the payload of actions that leads to navigation redirection.
 */

// Rehydration
export const rehydrateGalia = createAction(
  '[Galia] Rehydrate',
  props<{ user: User; token: string; dest: UrlTree }>()
);

export const openGaliaAppView = createAction(
  '[Galia App view] Open',
  props<{ dest: UrlTree }>()
);

export const navigationPopState = createAction(
  '[Browser] History click',
  props<{ dest: UrlTree }>()
);

export const rehydrationComplete = createAction(
  '[Galia] Rehydration complete.',
  props<{
    application: ApplicationVersion;
    optimSpace: OptimSpaceModel;
    input: InputModel;
    inputVisu: NewVisuData;
    inputKpis: Kpi[];
    output: OutputModel;
    outputVisu: NewVisuData;
    outputKpis: Kpi[];
  }>()
);

export const rehydrationFailure = createAction(
  '[Galia] Rehydration failure.',
  props<{ message: string }>()
);

// Data selection

/**
 * The selection of a new application erases the current OptimSpace, input, output,
 * and parameter set to prevent from data inconsistency.
 */
export const applicationSelection = createAction(
  '[Galia Applist] Application Selection',
  props<{ appPath: string; version: string }>()
);
export const optimSpaceSelection = createAction(
  '[Galia Data picker] OptimSpace Selection.',
  props<{ optimSpaceId: number; dest: UrlTree }>()
);

export const outputSelection = createAction(
  '[Galia Data picker] Output Selection.',
  props<{ outputId: number; dest: UrlTree }>()
);
export const parameterSelection = createAction(
  '[Galia Data picker] Parameter Selection.',
  props<{ parameterId: number; dest: UrlTree }>()
);

// reducers
export const applicationSelectionComplete = createAction(
  '[Application API] Application selection complete.',
  props<{
    app: ApplicationVersion;
  }>()
);

export const applicationVisualization = createAction(
  '[Application API] Application visualization.',
  props<{
    name: string;
    path: string;
  }>()
);

export const applicationSelectionFailure = createAction(
  '[Application API] Application selection failure.',
  props<{ message: string }>()
);

export const optimSpaceSelectionComplete = createAction(
  '[OptimSpace/Input API] Select optimSpace complete.',
  props<{
    optimSpace: OptimSpaceModel;
    input: InputModel;
    inputVisu?: NewVisuData;
    inputKpis?: Kpi[];
  }>()
);
export const optimSpaceSelectionFailure = createAction(
  '[OptimSpace/Input API] Select optimSpace failure.',
  props<{
    message: string;
    details?: { action: string; title: string; htmlMessage: string };
  }>()
);

export const outputSelectionComplete = createAction(
  '[Output API] Select output complete.',
  props<{ output: OutputModel; outputVisu?: NewVisuData; outputKpis?: Kpi[] }>()
);
export const outputSelectionFailure = createAction(
  '[Output API] Select output failure.',
  props<{
    message: string;
    details?: { action: string; title: string; htmlMessage: string };
  }>()
);

export const parameterSelectionComplete = createAction(
  '[Application API] Select parameter complete.',
  props<{ parameterId: number }>()
);
export const parameterSelectionFailure = createAction(
  '[Application API] Select parameter failure.',
  props<{
    message: string;
    details?: { action: string; title: string; htmlMessage: string };
  }>()
);
export const favoriteParameterSelectionCompleted = createAction(
  '[Application API] Select favorite parameter complete.',
  props<{ favoriteParameterId: number }>()
);
export const favoriteParameterSelectionFailure = createAction(
  '[Application API] Select favorite parameter failure.',
  props<{
    message: string;
    details?: { action: string; title: string; htmlMessage: string };
  }>()
);

//// reducers
export const toggleInputKpis = createAction('[Sidebar] toggle input kpis.');
export const toggleOutputKpis = createAction('[Sidebar] toggle output kpis.');
export const toggleTimeline = createAction('[Sidebar] toggle timeline.');
export const toggleList = createAction('[Sidebar] toggle list.');
export const togglePackage = createAction('[Sidebar] toggle package 3D.');
export const toggleWarehouse = createAction('[Sidebar] toggle warehouse 3D.');
export const toggleMap = createAction('[Sidebar] toggle map.');
export const toggleCaption = createAction('[Sidebar] toggle caption.');

export const resetApplication = createAction('[App view OnDestroy] Reset.');
export const resetApplicationSuccess = createAction('[App view OnDestroy] Reset success.');

export const optimizeRun = createAction(
  '[Optimize button] click: run optimize.',
  props<{ inputId: number; parameterId: number; version: string }>()
);
export const optimizeRunSuccess = createAction(
  '[Optimize API] optimization launched.',
  props<{
    appPath: string;
    jobId: number;
    inputId: number;
    optimSpaceId: number;
    category: AsyncJobCategory;
  }>()
);
export const optimizeRunFailure = createAction(
  '[Optimize API] optimization not launched.',
  props<{ message: string }>()
);

export const optimizeResponseTreatment = createAction(
  '[Optimization socket] response treatment (dispatch).',
  props<{ response: WebSocketResponseDto; category: AsyncJobCategory }>()
);
export const optimizeResponseShow = createAction(
  '[Optimization response] show solution.',
  props<{ response: WebSocketResponseDto; category: AsyncJobCategory }>()
);
export const optimizeResponseShowError = createAction(
  '[Optimize response] show solution failed',
  props<{ appPath: string; inputId: number; category: AsyncJobCategory; message: string }>()
);
export const optimizeResponseNotify = createAction(
  '[Optimization response] notify new solution.',
  props<{ response: WebSocketResponseDto; category: AsyncJobCategory }>()
);
export const optimizeResponseError = createAction(
  '[Optimization response] error.',
  props<{ output: OutputModel; errors: string[]; category: AsyncJobCategory }>()
);
export const optimizeResponseWarning = createAction(
  '[Optimization response] warning.',
  props<{
    warnings: string[];
    output: OutputModel;
    outputVisu: NewVisuData;
    outputKpis: Kpi[];
    category: AsyncJobCategory;
  }>()
);
export const optimizeResponseSuccess = createAction(
  '[Optimization response] success.',
  props<{
    output: OutputModel;
    outputVisu: NewVisuData;
    outputKpis: Kpi[];
    category: AsyncJobCategory;
  }>()
);

export const outputVisuUserUpdate = createAction(
  '[List view] drag & drop (solution change)',
  props<{
    sourcePrimAggPos: number;
    sourceElemValue: string;
    targetPrimAggPos: number;
    targetElemValue: string;
  }>()
);

export const markAppElementAsDone = createAction(
  '[List view] mark appElement as done',
  props<{
    appElementId: number;
  }>()
);

export const markAppElementAsNotDone = createAction(
  '[List view] mark appElement as not done',
  props<{
    appElementId: number;
  }>()
);

export const recomputeRun = createAction(
  '[Recompute button] click recompute.',
  props<{ outputId: number; outputVisuForRecompute: NewVisuData }>()
);

export const inputFilterSetDateRange = createAction(
  '[Input Filter] set date range',
  props<{ startDate: Date; endDate: Date }>()
);
export const outputFilterSetDateRange = createAction(
  '[Output Filter] set date range',
  props<{ startDate: Date; endDate: Date }>()
);

// These actions are very similar but a good pratice in redux suggests to create
// very specific actions.
export const inputFilterAddPrimaryAggregator = createAction(
  '[Input Filter] add primary aggregator',
  props<{ id: number }>()
);
export const outputFilterAddPrimaryAggregator = createAction(
  '[Output Filter] add primary aggregator',
  props<{ id: number }>()
);
export const inputFilterRmPrimaryAggregator = createAction(
  '[Input Filter] rm primary aggregator',
  props<{ id: number }>()
);
export const outputFilterRmPrimaryAggregator = createAction(
  '[Output Filter] rm primary aggregator',
  props<{ id: number }>()
);
export const inputFilterResetPrimaryAggregator = createAction(
  '[Input Filter] rm all primary aggregators.'
);
export const outputFilterResetPrimaryAggregator = createAction(
  '[Output Filter] rm all primary aggregators.'
);

export const optimSpaceUploadCompletion = createAction(
  '[OptimSpace Upload] completion',
  props<{ dest: UrlTree; optimSpaceId: number; initialInputId: number }>()
);
export const optimSpaceUploadComplete = createAction(
  '[OptimSpace and input API] Select optimSpace and input complete.',
  props<{
    optimSpace: OptimSpaceModel;
    input: InputModel;
    inputVisu?: NewVisuData;
    inputKpis?: Kpi[];
  }>()
);
export const optimSpaceUploadFailure = createAction(
  '[OptimSpace and Input API] Select optimSpace and input failure.',
  props<{ message: string }>()
);

export const parameterUploadComplete = createAction(
  '[OptimSpace upload] update parameters',
  props<{ dest: UrlTree; appPath: string; version: string; parameterId: number }>()
);

export const parameterUpdateComplete = createAction(
  '[Parameters API] parameters update complete',
  props<{ parameters: ParameterItemModel[]; parameterId: number }>()
);

export const parameterUpdateFailure = createAction(
  '[Parameters API] parameters update failure',
  props<{ message: string }>()
);

export const primaryAggregatorExport = createAction(
  '[List view] export click',
  props<{ primaryAggregatorId: number }>()
);

export const showInputVisu = createAction('[Input/Output Toggle] show input');

export const showOutputVisu = createAction('[Input/Output Toggle] show output');

export const exportPrimaryAggregatorSuccess = createAction('[Export API] export success');

export const closeStatusBar = createAction(
  '[Status bar] close',
  props<{ message: null }>()
);

export const mapChangeTransportMode = createAction(
  '[Map] change transport mode',
  props<{ transportMode: string; visuOnScreen: VisuScreenState }>()
);

export const setMapProvider = createAction(
  '[Map] set provider',
  props<{ provider?: MapProvider }>()
);

export const showInList = createAction(
  '[List view] show in list',
  props<{ aggregatorId: number }>()
);

export const clearList = createAction('[List view] clear list');
